import React from "react"
import Link from "gatsby-link"
import img from "../images/logo.png"
import bgrepeat from "../images/bgrepeat.png"
import { FaAngleDown } from "react-icons/fa"
import "../styles/nav.css"

class Nav extends React.Component {
  render() {
    return (
      <>
        <div
          className="bg-brown border-b-2 border-orange bg-blue flex items-center lg:h-70px"
          style={{ background: `url(${bgrepeat})` }}
        >
          <div className="flex items-center w-full justify-between flex-col lg:flex-row">
            <div style={{ padding: "10px" }}>
              <Link to="/">
                <img src={img} alt="logo" style={{ maxWidth: "248px", margin: "0px" }} />
              </Link>
            </div>

          {/* RESPONSIVE HAMBURGER MENU */}

            <div className="lg:hidden">

              <div className="menu-wrap">
                <div className="hamburger">
                  <div></div>
                </div>
                <input type="checkbox" className="toggler" />
                <div className=""></div>
                <div className="menu">
                  <div>
                    <div>
                      <ul>
                        <li className="">
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/magento-support"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Magento Support</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/magento-2-migration"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Magento 2 Migration</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/the-benefits-of-migrating-magento-1-to-magento-2"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Magento 2</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/shopify-support"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Shopify Support</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none", width: "100%" }}
                    to="/shopify-plus"

                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Shopify Plus</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/website-design"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Website Design</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/blog"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">Blog</div></Link>
                        </li>
                        <li>
                        <Link
                    style={{ textDecoration: "none" }}
                    to="/about"
                  ><div className="hover:bg-white hover:bg-opacity-75 hover:text-green transition-all duration-300">About</div></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="lg:flex hidden">
              <div className="text-white ">
                <div className="droplist relative px-5 font-hairline py-2 no-underline duration-300 transition-all hover:text-green">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/magento-support"
                  ><div className="flex">
                    <p>Magento Support</p><i className="pl-1 flex justify-center items-center"><FaAngleDown  /></i>
                    </div>
                  </Link>
                  <ul className="list one font-hairline text-grey">
                    <li className="w-full h-12 mb-0"></li>
                    <li className="w-full h-full item mb-0 hover:text-green">
                      <Link
                        to="/magento-2-migration"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Magento 2 Migration
                      </Link>
                    </li>
                    <li className="w-full h-full item mb-0 hover:text-green">
                      <Link
                        to="/the-benefits-of-migrating-magento-1-to-magento-2"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Magento 2
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-white">
                <div className="droplist relative px-5 font-hairline py-2 no-underline duration-300 transition-all hover:text-green">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/shopify-support"
                  >
                    <div className="flex">
                    <p>Shopify Support</p><i className="pl-1 flex justify-center items-center"><FaAngleDown  /></i>
                    </div>
                  </Link>
                  <ul className="list two font-hairline text-grey">
                    <li className="w-full h-12 mb-0"></li>
                    <li className="w-full h-full item mb-0 hover:text-green">
                      <Link
                        to="/shopify-plus"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Shopify Plus
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-white">
                <div className="px-5 font-hairline py-2 no-underline duration-300 transition-all hover:text-green">
                  <Link style={{ textDecoration: "none" }} to="/website-design">
                    <p>Website Design</p>
                  </Link>
                </div>
              </div>
              <div className="text-white">
                <div className="px-5 font-hairline py-2 no-underline duration-300 transition-all hover:text-green">
                  <Link style={{ textDecoration: "none" }} to="/blog">
                    <p>Blog</p>
                  </Link>
                </div>
              </div>
              <div className="text-white">
                <div className="px-5 font-hairline py-2 no-underline duration-300 transition-all hover:text-green">
                  <Link style={{ textDecoration: "none" }} to="/about">
                    <p>About</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Nav
