import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import SEO from "./seo"
import Footer from "./Footer"
import '../styles/main.css'
const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Nav />
      <main>{children}</main>
      <Footer/> 
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout