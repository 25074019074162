import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import logo from "../images/logo.png"
import PortableText from "@sanity/block-content-to-react"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"

const footerLinks = props => {
  return (
    <li
      className="no-underline mb-2 duration-300 transition-all hover:text-green"
      style={{ boxShadow: "none" }}
    >
      {props.children}
    </li>
  )
}

const Footer = props => {
  const data = useStaticQuery(graphql`
    query ComponentsQuery {
      allSanityComponents {
        edges {
          node {
            footerImg {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            _rawFooterBlock1
            _rawFooterBlock2
            _rawFooterBlock3
            _rawFooterBlock4
          }
        }
      }
    }
  `)
  const short = data.allSanityComponents.edges[0].node
  return (
    <>
      <div
        className="p-2"
        style={{
          backgroundImage: `url(${short.footerImg.asset.fluid.src})`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex justify-center">
          <div className="max-w-twelve lg:my-32 my-20 flex text-white font-hairline flex-col">
            <div className="flex lg:flex-row flex-col justify-center">
              <div className="lg:flex-1 flex flex-col items-center lg:items-start px-3 lg:max-w-250px">
                <img
                  src={logo}
                  width="240px"
                  style={{ marginBottom: "18px" }}
                  alt="logo"
                />
                <div className="lg:text-left text-center">
                  <PortableText blocks={short._rawFooterBlock1} />
                </div>
                <div className="flex mt-4 mb-12 lg:mb-0 lg:mt-8">
                  <a
                    className="bg-white bg-opacity-25 rounded hover:bg-white hover:text-green transition duration-300"
                    style={{ padding: "9px", marginRight: "10px" }}
                    target="_blank"
                    href="https://www.facebook.com/OrangeCollarInc/"
                    rel="noreferrer"
                  >
                    <FaFacebookF size="18px" />
                  </a>
                  <a
                    className="bg-white bg-opacity-25 rounded hover:bg-white hover:text-green transition duration-300"
                    style={{ padding: "9px", marginRight: "10px" }}
                    target="_blank"
                    href="https://twitter.com/orangecollar"
                    rel="noreferrer"
                  >
                    <FaTwitter size="18px" />
                  </a>
                  <a
                    className="bg-white bg-opacity-25 rounded hover:bg-white hover:text-green transition duration-300"
                    style={{ padding: "9px" }}
                    target="_blank"
                    href="https://www.linkedin.com/company/orange-collar-media/about/"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn size="18px" />
                  </a>
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:items-start items-center md:text-left text-center mb-4  lg:flex-3">
                <div className="flex-1 px-3 md:mb-0 mb-6">
                  <h3 className="text-xl mb-2 md:mb-3">SERVICES</h3>
                  <PortableText
                    blocks={short._rawFooterBlock2}
                    serializers={{ listItem: footerLinks }}
                  />
                </div>
                <div className="w-80 px-3 md:mb-0 mb-6">
                  <h3 className="text-xl mb-2 md:mb-3">DESIGN AGENCY</h3>
                  <PortableText
                    blocks={short._rawFooterBlock3}
                    serializers={{ listItem: footerLinks }}
                  />
                </div>
                <div className="flex-1 px-3 md:mb-0 mb-6">
                  <h3 className="text-xl mb-2 md:mb-3">CONTACT US</h3>
                  <PortableText
                    blocks={short._rawFooterBlock4}
                    serializers={{ listItem: footerLinks }}
                  />
                </div>
              </div>
            </div>
            <p className="text-center text-white mt-8">
              2009 – 2020 © ORANGE COLLAR MEDIA​
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
